import { IconBrackets } from '@codexteam/icons';
import 'highlight.js/styles/github.css';

export default class CodeTool {
  /**
     * Notify core that read-only mode is supported
     *
     * @returns {boolean}
     */
  static get isReadOnlySupported() {
    return true;
  }

  /**
     * Allow to press Enter inside the CodeTool textarea
     *
     * @returns {boolean}
     * @public
     */
  static get enableLineBreaks() {
    return true;
  }

  /**
     * @typedef {object} CodeData — plugin saved data
     * @property {string} code - previously saved plugin code
     */

  /**
     * Render plugin`s main Element and fill it with saved data
     *
     * @param {object} options - tool constricting options
     * @param {CodeData} options.data — previously saved plugin code
     * @param {object} options.config - user config for Tool
     * @param {object} options.api - Editor.js API
     * @param {boolean} options.readOnly - read only mode flag
     */
  constructor({
    data, config, api, readOnly,
  }) {
    this.api = api;
    this.readOnly = readOnly;

    this.placeholder = this.api.i18n.t(config.placeholder || CodeTool.DEFAULT_PLACEHOLDER);

    this.CSS = {
      baseClass: this.api.styles.block,
      input: this.api.styles.input,
      wrapper: 'ce-code',
      textarea: 'ce-code__textarea',
    };

    this.nodes = {
      holder: null,
      textarea: null,
    };

    this.data = {
      code: data.code || '',
    };

    this.nodes.holder = this.drawView();
  }

  drawViewReadOnly() {
    const wrapper = document.createElement('pre');
    const textarea = document.createElement('code');

    textarea.textContent = this.data.code;

    textarea.placeholder = this.placeholder;

    wrapper.appendChild(textarea);

    this.nodes.textarea = textarea;

    return wrapper;
  }

  /**
     * Create Tool's view
     *
     * @returns {HTMLElement}
     * @private
     */
  drawView() {
    if (this.readOnly) {
      return this.drawViewReadOnly();
    }

    const wrapper = document.createElement('div');
    const textarea = document.createElement('textarea');

    wrapper.classList.add(this.CSS.baseClass, this.CSS.wrapper);
    textarea.classList.add(this.CSS.textarea, this.CSS.input);
    textarea.textContent = this.data.code;

    textarea.placeholder = this.placeholder;

    if (this.readOnly) {
      textarea.disabled = true;
    }

    wrapper.appendChild(textarea);

    /**
       * Enable keydown handlers
       */
    textarea.addEventListener('keydown', (event) => {
      switch (event.code) {
        case 'Tab':
          this.tabHandler(event);
          break;
        default:
          break;
      }
    });

    this.nodes.textarea = textarea;

    return wrapper;
  }

  /**
     * Return Tool's view
     *
     * @returns {HTMLDivElement} this.nodes.holder - Code's wrapper
     * @public
     */
  render() {
    return this.nodes.holder;
  }

  /**
     * Extract Tool's data from the view
     *
     * @param {HTMLDivElement} codeWrapper - CodeTool's wrapper, containing textarea with code
     * @returns {CodeData} - saved plugin code
     * @public
     */
  save(codeWrapper) {
    return {
      code: codeWrapper.querySelector('textarea').value,
    };
  }

  /**
     * onPaste callback fired from Editor`s core
     *
     * @param {PasteEvent} event - event with pasted content
     */
  onPaste(event) {
    const content = event.detail.data;

    this.data = {
      code: content.textContent,
    };
  }

  /**
     * Returns Tool`s data from private property
     *
     * @returns {CodeData}
     */
  get data() {
    return this._data;
  }

  /**
     * Set Tool`s data to private property and update view
     *
     * @param {CodeData} data - saved tool data
     */
  set data(data) {
    this._data = data;

    if (this.nodes.textarea) {
      this.nodes.textarea.textContent = data.code;
    }
  }

  /**
     * Get Tool toolbox settings
     * icon - Tool icon's SVG
     * title - title to show in toolbox
     *
     * @returns {{icon: string, title: string}}
     */
  static get toolbox() {
    return {
      icon: IconBrackets,
      title: 'My code',
    };
  }

  /**
     * Default placeholder for CodeTool's textarea
     *
     * @public
     * @returns {string}
     */
  static get DEFAULT_PLACEHOLDER() {
    return 'Enter a code';
  }

  /**
     *  Used by Editor.js paste handling API.
     *  Provides configuration to handle CODE tag.
     *
     * @static
     * @returns {{tags: string[]}}
     */
  static get pasteConfig() {
    return {
      tags: ['pre'],
    };
  }

  /**
     * Automatic sanitize config
     *
     * @returns {{code: boolean}}
     */
  static get sanitize() {
    return {
      code: true, // Allow HTML tags
    };
  }

  /**
     * Handles Tab key pressing (adds/removes indentations)
     *
     * @private
     * @param {KeyboardEvent} event - keydown
     * @returns {void}
     */
  tabHandler(event) {
    /**
       * Prevent editor.js tab handler
       */
    event.stopPropagation();

    /**
       * Prevent native tab behaviour
       */
    event.preventDefault();

    const textarea = event.target;
    const isShiftPressed = event.shiftKey;
    const caretPosition = textarea.selectionStart;
    const { value } = textarea;
    const indentation = '  ';

    let newCaretPosition;

    /**
       * For Tab pressing, just add an indentation to the caret position
       */
    if (!isShiftPressed) {
      newCaretPosition = caretPosition + indentation.length;

      textarea.value = value.substring(0, caretPosition) + indentation + value.substring(caretPosition);
    } else {
      /**
         * For Shift+Tab pressing, remove an indentation from the start of line
         */
      const currentLineStart = value.lastIndexOf('\n', caretPosition - 1) + 1;
      const firstLineChars = value.substr(currentLineStart, indentation.length);

      if (firstLineChars !== indentation) {
        return;
      }

      /**
         * Trim the first two chars from the start of line
         */
      textarea.value = value.substring(0, currentLineStart) + value.substring(currentLineStart + indentation.length);
      newCaretPosition = caretPosition - indentation.length;
    }

    /**
       * Restore the caret
       */
    textarea.setSelectionRange(newCaretPosition, newCaretPosition);
  }
}
